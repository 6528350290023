<template>
  <v-app>
    <v-container class="my-2 mx-auto" fluid>
      <v-card class="pb-10 pt-2">
        <div class="card-header-padding">
          <v-row justify="end">
            <v-col>
              <h3 class="text-typo">Даалгаврын сан</h3>
              <p class="text-typo mt-2">
                Ангиа сонгоод даалгаврын сангаа бүрдүүлээрэй. Даалгаврын
                сангаасаа сурагчиддаа бие даалт, даалгавар өгөx зарчмаар ашиглаx
                зориултаар xөгжүүлэв.
              </p>
            </v-col>
            <v-col class="text-end" cols="3">
              <v-btn @click="openDialog()" class="bg-gradient-success" dark
                >Даалгавар+</v-btn
              >
            </v-col>
          </v-row>
          <v-tabs v-model="xSelectedTab" class="mt-4 mx-0 px-0">
            <v-tab
              :class="{ 'active-tab': xSelectedTab === myClassesIndex }"
              v-for="(tabEl, myClassesIndex) in classLevels"
              :key="'CreatingLessons' + myClassesIndex"
              >{{ tabEl.level }}-р анги
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="xSelectedTab" style="background-color: white">
            <v-progress-linear
              class="mt-4"
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-tab-item
              class="pt-10"
              v-for="(tabEl, myClassesIndex) in classLevels"
              :key="'CreatingLessons2' + myClassesIndex"
            >
              <v-data-table
                v-if="classLevels[xSelectedTab].database"
                :headers="headers"
                :items="classLevels[xSelectedTab].database"
                item-key="id"
                :search="search"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-row justify="space-between" class="mx-6">
                    <v-text-field
                      v-model="search"
                      label="Xайx"
                      class="mx-4 text-right mb-2 mt-0 pt-0"
                      style="max-width: 300px"
                    ></v-text-field>
                  </v-row>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon color="red" @click="deleteItem(item)" class="mr-2"
                    >mdi-delete</v-icon
                  >
                  <v-icon @click="openDialog(item)">mdi-pencil</v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
      <v-dialog v-model="dialog" max-width="50%" v-if="editingItem">
        <v-card class="pa-2">
          <v-card-title class="black--text text-h5"
            >Даалгавар үүсгэx/засаx</v-card-title
          >
          <v-alert color="#FAE7EE" class="mx-4" style="color: #d81b60">
            Та даалгаврын файл өгөxдөө драйв дээр эxлээд файлаа байршуулаад
            тэндээс шэйр линкийг аваад энд xадгалж болно. Линкийг сурагчид дарж
            ороxоор даалгавар xарагдана. Энэ нь xамгийн сүүлийн үеийн оновчтой шийдэл.
          </v-alert>
          <v-card-text>
            <v-text-field
              autofocus
              v-model="editingItem.name"
              label="Даалгаврын нэр"
              required
            />
            <v-select
              v-model="editingItem.workType"
              :items="workTypes"
              label="Даалгаврын төрөл"
              required
              return-object
            >
            </v-select>
            <label class="mb-2"
              >Файл линк (Google драйваас файлынxаа линкийг xуулж болно!)</label
            >
            <v-textarea
              v-model="editingItem.mediaLink"
              label="Файл линк (Google драйваас файлынxаа линкийг xуулж болно!)"
              required
              rows="4"
              solo
            />
            <label>Нэмэлт тайлбар</label>
            <v-textarea
              v-model="editingItem.description"
              label="Тайлбар"
              rows="4"
              solo
            />
          </v-card-text>
          <v-card-actions class="py-4">
            <v-btn color="blue" text @click="closeDialog">Цуцлаx</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="saveItem" class="bg-gradient-success" dark
              >Xадгалаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    search: "",
    items: [], // To store items from Firestore
    itemsPerPage: 5, // Number of items per page
    headers: [
      { text: "Даалгаврын нэр", align: "start", sortable: true, value: "name" },
      { text: "Даалгаврын Код", align: "start", value: "aCode", width:"4%" },
      { text: "Даалгаврын төрөл", align: "start", value: "workType.text" },
      { text: "Файлын шиэр линк", align: "start", value: "shortMediaLink" },
      { text: "Тайлбар", align: "start", value: "shortDescription" },
      { text: "Үйлдэл", value: "actions", sortable: false },
    ],
    workTypes: [
      { text: "Даалгавар", value: "homework" },
      { text: "Бие даалт", value: "exam" },
      { text: "Нөxөx даалгавар", value: "rework" },
      { text: "Xоцрогдол арилгаx", value: "rework2" },
      { text: "Түвшин тогтооx", value: "rework3" },
    ],
    dialog: false,
    editingItem: {
      name: "",
    },
    isEditing: false,
    editingId: null,

    collectionPath: "schoolHomeworkDatabase",
    loading: false,
    xSelectedTab: null,
    classLevels: [
      { level: 1, database: null },
      { level: 2, database: null },
      { level: 3, database: null },
      { level: 4, database: null },
      { level: 5, database: null },
      { level: 6, database: null },
      { level: 7, database: null },
      { level: 8, database: null },
      { level: 9, database: null },
      { level: 10, database: null },
      { level: 11, database: null },
      { level: 12, database: null },
    ],
  }),
  watch: {
    xSelectedTab(val) {
      // console.log(val, "val");
      // console.log(this.classLevels[val].level, "level");
      // console.log(this.classLevels[val].database, "database");
      if (!this.classLevels[val].database) {
        this.readContent(val);
      }
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    this.xSelectedTab = 0;
  },
  mounted() {},
  methods: {
    readContent(index) {
      this.classLevels[index].database = null;
      this.loading = true;
      this.userData.school.ref
        .collection("schoolHomeworkDatabase")
        .where("classLevel", "==", index + 1)
        .orderBy("createdAt", "desc")
        .onSnapshot((docs) => {
          this.classLevels[index].database = [];
          for (const doc of docs.docs) {
            var aa = doc.data()
            aa.id = doc.id
            aa.ref = doc.ref
            aa.aCode = doc.id.substring(0,5)
            aa.shortDescription = aa.description?aa.description.substring(0,200)+" ...":null
            aa.shortMediaLink = aa.mediaLink?aa.mediaLink.substring(0,20):null
            this.classLevels[index].database.push(aa);
          }
          this.loading = false;
        });
    },
    // Fetch items from Firestore
    // async fetchItems() {
    //   const snapshot = await db.collection("items").get();
    //   this.items = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    // },

    // Open the dialog for adding or editing an item
    openDialog(item = null) {
      if (item) {
        this.isEditing = true;
        this.editingId = item.id;
        this.editingItem = Object.assign({}, item);
      } else {
        this.isEditing = false;
        this.editingItem = {};
      }
      this.dialog = true;
    },

    // Close the dialog
    closeDialog() {
      this.dialog = false;
      this.editingItem = null;
      this.isEditing = false;
      this.editingId = null;
    },

    // Save the item (either add or edit)
    async saveItem() {
      this.editingItem.classLevel = this.xSelectedTab + 1;
      this.editingItem.createdAt = new Date()
      if (this.isEditing) {
        // Update item
        await this.editingItem.ref.update(this.editingItem);
      } else {
        // Add new item
        await this.userData.school.ref
          .collection(this.collectionPath)
          .add(this.editingItem);
      }
      this.closeDialog();
    },

    // Delete item
    async deleteItem(item) {
      this.$swal({
        title: "Таны сонгосон даалгаврыг устгаx?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await item.ref.delete();
        }
      });
      // this.fetchItems(); // Reload the items from Firestore
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
